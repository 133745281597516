require('idempotent-babel-polyfill');

function loadUtils(opts) {
  opts = opts || {};

  var $ = opts.$;
  var Promise = opts.Promise;
  var dbaClient = opts.dbaClient;

  fbq('track', 'Reached 1st page of initial registration', {
    value: 0,
    currency: 'GBP'
  });

  function redirectToSign(leadId) {
    $('#instructions').modal('show');

    var skipped = false;

    var tmtId = setTimeout(function () {
      if (!skipped) {
        location.href = dbaClient.uri + 'sign/' + leadId;
      }
    }, 10000);

    $('#skip-instructions').click(function () {
      clearTimeout(tmtId);
      location.href = dbaClient.uri + 'sign/' + leadId;
    });
  }

  function showAgreement() {
    return new Promise(function (resolve) {
      $('#infsheet').modal('show'); // display information sheet

      // Disagree on information sheet
      $('#infosheet-btn-disagree').click(function () {
        $('#infsheet').modal('hide');

        resolve(0);
      });

      // Accept information sheet
      $('#infosheet-btn-agree').click(function () {
        $('#infsheet').modal('hide');

        resolve(1);
      });
    });
  }

  var _lead;

  function buildLead() {
    if (_lead) {
      return _lead;
    }

    var lead = {};

    lead.leadgroup = dbaClient.leadGroup;
    lead.site = 0;
    lead.introducer = 0;
    lead.reference = '';

    var adsinfo = getUrlVars(window.location.href);
    lead.source = adsinfo.utm_source;
    lead.medium = adsinfo.utm_medium;
    lead.term = adsinfo.utm_term;

    lead.cost = 120;
    lead.value = 120;

    let firstName = $("#first-name").val().trim();
    let lastName = $("#last-name").val().trim();

    // get address and postcode
    lead.firstname = firstName;
    lead.lastname = lastName;
    lead.email = $("#email").val();
    lead.phone1 = $("#phone").val();
    lead.address = $("#housenum").val() + " " + $("#streetname").val();
    lead.towncity = $("#towncity").val();
    lead.postcode = $("#postcode").val();

    // get date of birth
    var dob = new Date($("#dob-calendar").datepicker("getDate"));
    lead.dobday = dob.getDate();
    lead.dobmonth = dob.getMonth() + 1;
    lead.dobyear = dob.getFullYear();

    // set contact preferences to yes
    lead.contactphone = "Yes";
    lead.contactsms = "Yes";
    lead.contactemail = "Yes";
    lead.contactmail = "Yes";
    lead.contactfax = "Yes";

    if (lead.dobday < 10) {
      lead.dobday = "0" + lead.dobday;
    }

    if (lead.dobmonth < 10) {
      lead.dobmonth = "0" + lead.dobmonth;
    }

    lead.data6 = 'Yes'; // permissionAccessInfo
    lead.data7 = 'Yes'; // gaveConsentData
    lead.data8 = 'Yes'; // agreedExperianCheck
    lead.data41 = 'Yes'; // workedHourlyRate

    var campaignURL = window.location.href;
    lead.data46 = campaignURL.replace(/&/g, '&' + 'amp' + ';');
    lead.data48 = $('input[name="gender"]:checked + label').text();

    _lead = lead;

    return lead;
  }

  function transitionFromTo(step1Id, step2Id, setPercent, stepNumber) {
    $('#' + step1Id).hide();
    $('#' + step2Id).show();

    $('#progress-bar').progress({
      percent: setPercent
    });

    $("#progress-step").text("Step " + stepNumber + " of 3");
  }

  $('#step1').form({
    fields: {
      question1: {
        identifier: 'question1',
        rules: [
          {
            type: 'checked',
            prompt: 'You must have worked at Morrisons for past 6 years on an hourly rate to submit a claim'
          }
        ]
      }
    },
    onSuccess: function (e, fields) {
      fbq('track', 'Reached 2nd page of initial registration', {
        value: 0,
        currency: 'GBP'
      });
      transitionFromTo('step1', 'step2', 33, 2);
    }
  });

  $("#step1-next").click(function () {
    $('#step1').form('validate form');
  });

  // add custom validation for postocde
  $.fn.form.settings.rules.validPostcode = function (param) {
    var postcode = $("#postcode").val().replace(/\s/g, "");
    var regex = /^[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}$/i;
    return regex.test(postcode);
  };

  $('#step2').form({
    fields: {
      question1: {
        identifier: 'postcode',
        rules: [
          {
            type: 'validPostcode[]',
            prompt: 'You need to provide valid postcode to continue'
          }
        ]
      },
      townCity: {
        identifier: 'towncity',
        rules: [
          {
            type: 'empty',
            prompt: 'You need to provide city/town name to continue'
          }
        ]
      },
      streetname: {
        identifier: "streetname",
        rules: [
          {
            type: 'empty',
            prompt: 'You need to provide street name to continue'
          }
        ]
      }
    },
    onSuccess: function (e, fields) {
      fbq('track', 'Reached 3rd page of initial registration', {
        value: 0,
        currency: 'GBP'
      });
      transitionFromTo('step2', 'step3', 66, 3);
    }
  });

  $("#step2-back").click(function () {
    transitionFromTo('step2', 'step1', 0, 1);
  });

  $("#step2-next").click(function () {
    $('#step2').form('validate form');
  });


  // add custom validation for birth
  $.fn.form.settings.rules.validBirthDay = function (param) {
    var currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() - 18);
    var date = Date.parse($("#dob-calendar").datepicker("getDate"));
    if (isNaN(date)) {
      return false;
    }
    if (new Date($("#dob-calendar").datepicker("getDate")) > currentDate) {
      return false;
    }
    return true;
  };

  $('#step3').form({
    fields: {
      first_name: {
        identifier: 'first-name',
        rules: [
          {
            type: 'empty',
            prompt: 'You need to provide your first name'
          },
          {
            type: "regExp[/^[\\w'\\-,.][^0-9_!¡?÷?¿\\\+=@#$%ˆ&*(){}|~<>;:[\\]]{2,}$/]",
            prompt: 'Please enter a first name without any special characters'
          }
        ]
      },
      last_name: {
        identifier: 'last-name',
        rules: [
          {
            type: 'empty',
            prompt: 'You need to provide your last name'
          },
          {
            type: "regExp[/^[\\w'\\-,.][^0-9_!¡?÷?¿\\\+=@#$%ˆ&*(){}|~<>;:[\\]]{2,}$/]",
            prompt: 'Please enter a last name without any special characters'
          }
        ]
      },
      email: {
        identifier: 'email',
        rules: [
          {
            type: 'email',
            prompt: 'You need to provide your email address'
          }
        ]
      },
      phone: {
        identifier: 'phone',
        rules: [
          {
            type: 'empty',
            prompt: 'You need to provide a phone number to continue'
          }
        ]
      },
      birth_day: {
        identifier: 'dob-calendar',
        rules: [
          {
            type: 'validBirthDay[]',
            prompt: 'We are currently not taking on claims for individuals under the age of 18. Please contact us again once you have turned 18 and we will consider whether you have a claim'
          }
        ]
      }
    },
    onSuccess: function (e, fields) {
      fbq('track', 'Reached 4th page of initial registration', {
        value: 0,
        currency: 'GBP'
      });
      transitionFromTo('step3', 'step4', 100, 3);
      $("#progress-bar").hide();
    }
  });

  $("#step3-back").click(function () {
    transitionFromTo('step3', 'step2', 33, 2);
  });

  $("#step3-next").click(function () {
    $('#step3').form('validate form');
  });

  $('#step4').form({
    fields: {
      agree1: {
        identifier: 'agree1',
        rules: [
          {
            type: 'checked',
            prompt: 'You must give permission for Pay Justice to have access to the provided information above.'
          }
        ]
      },
      agree2: {
        identifier: 'agree2',
        rules: [
          {
            type: 'checked',
            prompt: 'You must give permission for Pay Justice to have access to the provided information above.'
          }
        ]
      },
      agree3: {
        identifier: 'agree3',
        rules: [
          {
            type: 'checked',
            prompt: 'You must give permission for Pay Justice to have access to the provided information above.'
          }
        ]
      }
    },
    onSuccess: async function (e, fields) {
      async function showAgree(leadId) {
        const agreed = await showAgreement();
        await dbaClient.confirm(leadId, agreed);
        if(agreed) {
          fbq('track', 'Agreed info sheet but not signed DBA', {
            value: 0,
            currency: 'GBP'
          });
          redirectToSign(leadId);
        }
      }

      async function checkExperian(leadId) {
        try {
          await dbaClient.checkExperianAndUpdate(leadId);
        } catch(e) {
          console.error(e);
        }
      }

      try {
        const lead = buildLead();
        const resp = await dbaClient.exists({ email: lead.email, state: 'morrisons' });
        if(resp) {
          $('.emailAddress').text(lead.email)
          $('#emailConfirmation').modal({
            onApprove: async function() {
              $("#loading").addClass("active");
              if(resp.not_checked) {
                // Exists but not checked and not agreed
                await checkExperian(resp.id);
                await showAgree(resp.id);
              } else if(resp.not_agreed) {
                await showAgree(resp.id);
              } else {
                redirectToSign(resp.id);
              }
      
              fbq('track', 'Lead', {
                value: 0,
                currency: 'GBP'
              });
        
              fbq('track', 'Viewed info sheet but not signed', {
                value: 0,
                currency: 'GBP'
              });
      
              $("#loading").removeClass("active");
            },
            onDeny: function() {
              return;
            }
          }).modal('show')
        } else {
          // New
          $("#loading").addClass("active");
          const result = await dbaClient.createLead(lead);
          await checkExperian(result.id);
          await showAgree(result.id);
          $("#loading").removeClass("active");
        }
      } catch(e) {
        console.error(e);
      }
    }
  });

  $("#step4-back").click(function () {
    transitionFromTo('step4', 'step3', 100, 3);
    $("#progress-bar").show();
  });

  $("#step4-next").click(function () {
    $('#step4').form('validate form');
  });

  $("#infosheet").scroll(function () {
    if ($(this).scrollTop() + $(this).innerHeight() >= $(this)[0].scrollHeight - $(this).scrollTop()) {
      $("#infosheet-btn-agree").removeClass("disabled");
    }
  });

  $('#dob-calendar').datepicker({
    changeMonth: true,
    changeYear: true,
    yearRange: '1910:2021',
    // startMode: 'year',
    type: 'date',
    useCurrent: true,
    dateFormat: 'dd/mm/yy'
  });
};

window.loadUtils = loadUtils;
